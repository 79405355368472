:root {
    --main-color-one: #F76631;
    --main-color-two: #524EB7;
    --main-color-three: #FCDA69;
    --heading-color: #333333;
    --secondary-color: #FBA260;
    --bg-light-one: #F5F9FE;
    --bg-light-two: #FEF8F3;
    --bg-dark-one: #040A1B;
    --bg-dark-two: #22253F;
    --paragraph-color: #667085;
    --paragraph-color-two: #475467;
    --paragraph-color-three: #D0D5DD;
    --paragraph-color-four: #344054;
    --heading-font: "Ubuntu", sans-serif;
    --body-font: "Ubuntu", sans-serif;
}

.section.nn_footerfirstpart {
    background-color: transparent;
    background: transparent;
}

/* admin panel logo */
.adminlog {
    width: 120px;
}

.mboilehidee {
    display: none !important;
}

.sidelogosz {
    width: 75px !important;
    max-width: unset;
}

.iconbgvew img {
    width: 32px;
    height: 32px;
}

.cls_cate_mbl {
    padding: 0px 20px;
    margin-bottom: 20px;
}

/* .ReactModal__Content.ReactModal__Content--after-open.Modal11,.resposnsmallscree,.wrapprsear{
    display: none;
} */
/*languange translation Admin panel issues*/

.cls_subtotal {
    display: inline-block;
    width: 88%;
    text-align: right;
    margin: 25px 0;
}

.cls_subtotal span {
    margin: 0 70px;
    font-size: 17px;
}

.cls_subtotal strong {
    font-size: 17px;
    width: 130px;
    display: inline-block;
}

.cls_subtotal .cls_border {
    border-top: 1px solid #ddd;
    display: inline-block;
    border-bottom: 1px solid #ddd;
    padding: 8px 0;
    font-weight: bolder;
}

.cls_admin_orderwhole p {
    margin: 0;
}

.langupload input {
    opacity: 0;
}

.langupload img {
    width: 400px;
    object-fit: contain;
    height: 100px;
}

.languploadcar input {
    opacity: 0;
}

.languploadcar img {
    width: 200px;
    object-fit: contain;
    height: 100px;
}

.input-tag {
    background: white;
    border-bottom: 1px solid #d6d6d6;
    border-radius: 2px;
    display: flex;
    flex-wrap: wrap;
    padding: 5px 5px 0;
}

.input-tag input {
    border: none;
    width: 100%;
}

.input-tag input:focus {
    border: none;
    outline: none;
}

.input-tag__tags {
    display: inline-flex;
    flex-wrap: wrap;
    margin: 0;
    padding: 0;
    width: 100%;
}

.input-tag__tags li {
    align-items: center;
    background: #85A3BF;
    border-radius: 2px;
    color: white;
    display: flex;
    font-weight: 300;
    list-style: none;
    margin-bottom: 5px;
    margin-right: 5px;
    padding: 5px 10px;
}

.input-tag__tags li button {
    align-items: center;
    appearance: none;
    background: #333333;
    border: none;
    border-radius: 50%;
    color: white;
    cursor: pointer;
    display: inline-flex;
    font-size: 12px;
    height: 15px;
    justify-content: center;
    line-height: 0;
    margin-left: 8px;
    transform: rotate(45deg);
    width: 15px;
}

.input-tag__tags li.input-tag__tags__input {
    background: none;
    flex-grow: 1;
    padding: 0;
}

.serviseadmin label[class*="CustomInput-labelRoot-"] {
    transform: none !important;
}

.serviseadmin label.jss531 {
    transform: none !important;
}

.languagechange {
    display: block !important;
}

.languagechange span {
    position: relative;
    z-index: -4;
}

.disabledEditurl {
    pointer-events: none;
    background-color: #ccc;
    height: 58px;
    padding-left: 10px;
    margin-bottom: 30px;
    margin-top: 50px;
}

.closebuttonissues {
    width: 100%;
    display: inline-block;
    margin-bottom: 25px;
}

.sesslesubmit {
    margin-top: 30px;
}

#appleid-signin {
    margin-top: 10px;
    background-color: #000;
    border-radius: 50px;
    cursor: pointer;
}

#appleid-signin img {
    width: 100%;
    height: 50px;
    object-fit: contain;
}

.cls_apple {
    background-color: #000;
}

.cls_locslider .rangeslider-horizontal {
    height: 2px;
    border-radius: 10px;
}

.cls_locslider .rangeslider-horizontal .rangeslider__fill {
    background-color: #f0759b;
}

.cls_locslider .rangeslider .rangeslider__handle {
    background: #f0759b;
    border: 1px solid #f0759b;
    box-shadow: 0 1px 3px rgba(254, 58, 86, 0.4), 0 -1px 3px rgba(254, 58, 86, 0.4);
    width: 40px;
    height: 40px;
    border-radius: 40px;
}

.cls_locslider .rangeslider-horizontal .rangeslider__handle:after {
    background-color: #f54a61;
    width: 21px;
    height: 21px;
    top: 9px;
    left: 9px;
    box-shadow: 0 1px 3px #f54a61 inset, 0 -1px 3px #f54a61 inset;
}

.cls_locslider .rangeslider .rangeslider__handle-tooltip {
    background-color: #f54a61;
    width: 100px;
    height: 40px;
    border-radius: 50px;
}

.cls_locslider .rangeslider .rangeslider__handle-tooltip span {
    font-size: 16px;
    color: #fff;

}

.cls_locslider .rangeslider__handle:focus {
    outline: none;
}

.cls_locslider .rangeslider-horizontal .rangeslider__handle-tooltip:after {
    border-top: 8px solid #f54a61;
}

.cls_locslider .rangeslider__labels {
    display: none;
}

.cls_locslider .rangeslider, .rangeslider .rangeslider__fill {
    box-shadow: inset 0 1px 3px #f54a61;
}

.cls_locslider i {
    font-size: 30px;
    color: #f54a61;
}

.cls_locslider {
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    margin: 20px 0;
    text-align: center;
}

.cls_tablescroll {
    max-height: 270px;
    overflow: auto;
    -webkit-overflow-scrolling: touch;
    display: inline-block;
    width: 100%;
}

.cls_tablescroll thead th {
    position: sticky;
    top: -1px !important;
    background-color: #f2f2f2 !important;
    z-index: 99;
}

.cls_tablescroll tbody td {
    vertical-align: middle;
}

.restto {
    margin-top: 70px;
}

.iHQQug article {
    padding-bottom: 24px;
}

.nn_discardPopup {
    width: 100%;
}

.nn_discardPopup h3 {
    font-size: 22px;
    font-weight: bold;
    margin-bottom: 0px;
}

.nn_discardPopup, .nn_article {
    text-align: center;
}

.sav_chang .btn-danger {
    background: #f54a61 !important;
    border-radius: 32px;
    border: 1px solid #f54a61 !important;
}

.sav_chang.cancee {
    margin-bottom: 15px;
}

.nn_popup_title {
    font-weight: bold;
    font-size: 17px;
    text-align: center;
}

.btn1, .btn2 {
    border-radius: 24px;
    padding: 8px 20px;
    line-height: 1;
    font-size: 16px;
    font-weight: bold;
    min-height: 40px;
    letter-spacing: .2px;
}

.btn1 {
    background-color: #f54a61;
    color: #FFF;
    border: 1px solid #f54a61;
}

.btn2 {
    color: #f54a61;
    border: 1px solid #f54a61;
    background-color: #FFF;
}

.nn_discard_btn {
    text-align: center;
}

.nn_discard_btn .btn1, .nn_discard_btn .btn2 {
    margin: 0px 10px;
}

/* admin login page */

.cls_warningmsg h4 {
    font-size: 20px;
}

.cls_warningmsg p {
    font-size: 18px;
    margin: 2px 0;
}

.cls_warningmsg a {
    color: #fff;
    font-weight: bold;
    text-decoration: underline;
}

.blink {
    animation: blink 0.5s infinite;
    -webkit-animation: blink 0.5s infinite;
    font-weight: bold;
}

@keyframes blink {
    30% {
        opacity: 0.0
    }
}

.cls_warningmsg h3 {
    font-size: 35px;
    color: #ffffff;
    text-transform: uppercase;
    font-weight: 600;
    margin: 0;
    display: inline-block;
    background-color: #f54a61;
    padding: 6px 10px 9px 10px;
    width: 450px;
    max-width: 100%;
    box-sizing: border-box;
    border-radius: 5px;
    margin-top: -44px;
    margin-bottom: 35px;
}

.cls_bgcolor {
    background-color: #00000054;
    border-radius: 30px;
}

.ps.ps--active-y {
    overflow-y: auto !important;
    overflow-x: hidden !important;
    margin-right: -7px;
}

/* .nn_etprofile .filepicker.dropzone.nn_img.dz-clickable:last-child::after,
.filepicker.dropzone.dz-clickable.dz-started .dropZoneDefault,
.nn_profile .filepicker.dropzone.nn_img.dz-clickable:last-child::after{
    display: none;
} */
.addimg .nn_etprofile .filepicker.dropzone.nn_img.dz-clickable.dz-started:last-child::after,
.addimg .nn_profile .filepicker.dropzone.nn_img.dz-clickable.dz-started:last-child::after {
    display: inline-block !important;
    opacity: 1;
}

.addimg1 .nn_etprofile .filepicker.dropzone.nn_img.dz-clickable.dz-started:last-child::after,
.addimg1 .nn_profile .filepicker.dropzone.nn_img.dz-clickable.dz-started:last-child::after {
    display: inline-block !important;
    opacity: 0;
}

.nn_create_product .addimg, .nn_edit_product .addimg {
    pointer-events: unset;
}

.nn_create_product .addimg1, .nn_edit_product .addimg1 {
    pointer-events: none;
}

.nn_create_product .dz-details button, .nn_edit_product .dz-details button {
    pointer-events: visible;
}

.filepicker.dropzone.dz-clickable.dz-started .dz-default.dz-message

/* .filepicker.dropzone.nn_img.dz-clickable .dz-default.dz-message */
    {
    display: none;
}

.filepicker.dropzone.dz-clickable.dz-started {
    width: 100%;
    padding: 5px;
}

.filepicker.dropzone.dz-clickable img {
    width: auto;
    object-fit: contain;
    height: 375px;
}

.filepicker .dz-preview~.dz-preview {
    width: auto !important;
    margin: 0px 20px 10px 0px;
}

.filepicker .dz-preview~.dz-preview img {
    width: 88px !important;
    height: 88px !important;
}

.dz-preview.dz-clickable.dz-image-preview {
    display: inline-block;
    padding: 5px;
    margin-bottom: 10px;
}

.dz-details img {
    border-radius: 8px;
}

.filepicker.dropzone.dz-clickable.dz-started {
    text-align: center;
}

.filepicker.dropzone.dz-clickable.dz-started.nn_img {
    position: relative;
    text-align: center;
}

/* .nn_edit_product .dz-preview.dz-clickable.dz-complete.dz-image-preview{
    display: block !important;
} */
.filepicker.dropzone.dz-clickable.dz-started div:nth-child(2) {
    display: block;
}

.nn_edit_product .dz-preview.dz-clickable.dz-image-preview,
.nn_create_product .dz-preview.dz-clickable.dz-image-preview {
    display: block !important;
}

.nn_edit_product .filepicker .dz-preview~.dz-preview,
.nn_create_product .filepicker .dz-preview~.dz-preview {
    display: inline-block !important;
}

.filepicker.dropzone.nn_img.dz-clickable {
    text-align: center;
}

.dz-clickable {
    cursor: pointer;
}

.nn_edit_product .filepicker .dz-preview~.dz-preview,
.nn_create_product .filepicker .dz-preview~.dz-preview {
    margin: 0px 55px 10px -45px;
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
}

.filepicker.dropzone.dz-clickable.dz-started {
    text-align: center;
}

.filepicker.dropzone.dz-clickable.dz-started.nn_img {
    position: relative;
    text-align: center;
}

.filepicker.dropzone.dz-clickable.nn_img.nn_img:last-child::after,
.filepicker.dropzone.dz-clickable.dz-started.nn_img:last-child::after {
    content: "+";
    border: 1px solid rgb(245, 245, 245);
    height: 90px;
    width: 90px;
    position: absolute;
    border-radius: 8px;
    text-align: center;
    line-height: 90px;
    font-size: 40px;
    margin-top: 5px;
    margin-left: -45px;
    color: #f54a61;
    box-shadow: 0px 0px 1px 1px rgba(0, 0, 0, 0.25);
    /* display: block;
    margin: 0 auto; */
}

/* .nn_edit_product .dz-preview.dz-clickable.dz-complete.dz-image-preview{
    display: block !important;
} */
.filepicker.dropzone.dz-clickable.dz-started div:nth-child(2) {
    display: block;
}

.nn_edit_product .dz-preview.dz-clickable.dz-image-preview,
.nn_create_product .dz-preview.dz-clickable.dz-image-preview {
    display: block !important;
}

.nn_edit_product .filepicker .dz-preview~.dz-preview,
.nn_create_product .filepicker .dz-preview~.dz-preview {
    display: inline-block !important;
}

.filepicker.dropzone.nn_img.dz-clickable {
    text-align: center;
}

.dz-clickable {
    cursor: pointer;
}

.nn_edit_product .filepicker .dz-preview~.dz-preview,
.nn_create_product .filepicker .dz-preview~.dz-preview {
    margin: 0px 55px 10px -45px;
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
}

.validatcolor {
    color: #f54a61;
}

#colorCode {
    max-width: 100% !important;
}

.textArea {
    width: 100%;
}

.textArea>::after {
    border-color: #f54a61 !important;
}

.nn_stuffimg {
    background-color: #f2f2f2;
    box-shadow: 0px 0px 5px 0px rgba(0, 0, 0, 0.25);
}

.nn_create_product .filepicker.dropzone.nn_img.dz-clickable::after, .nn_create_product .filepicker.dropzone.dz-clickable.nn_img.nn_img:last-child::after {
    display: none;
}

.custom_color_list {
    position: relative;
    max-height: 200px;
    overflow: auto;
}

.cls_colorpalate input {
    display: none;
}

label.cls_colorpalate {
    text-align: center;
    margin: 3px 10px;
    padding: 5px 0;
    width: 9%;
}

.cls_colorpalate p {
    margin: 0;
    font-size: 11px;
}

label.cls_colorpalate.active {
    border: 1px solid #00000021;
    box-shadow: inset 0px -1px 9px 2px #00000021;
}

.cls_colorpalate .button {
    display: inline-block;
    position: relative;
    width: 25px;
    height: 25px;
    margin: 2px 10px;
    cursor: pointer;
}

.cls_colorpalate .button span {
    display: block;
    position: absolute;
    width: 25px;
    height: 25px;
    padding: 0;
    top: 50%;
    left: 50%;
    -webkit-transform: translate(-50%, -50%);
    -ms-transform: translate(-50%, -50%);
    -o-transform: translate(-50%, -50%);
    transform: translate(-50%, -50%);
    border-radius: 100%;
    background: #eeeeee;
    box-shadow: 0 2px 5px 0 rgba(0, 0, 0, 0.26);
    transition: ease .3s;
}

.cls_colorpalate .button span:hover {
    border-radius: 20%;
}

.cls_colorpalate .layer {
    display: block;
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    background: transparent;
    /*transition: ease .3s;*/
    z-index: -1;
}


.nn_share_ctn {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 10px;
    border-bottom: 1px solid #ddd;
    text-align: center;
}

.centeralignnochat1 {
    padding: 10px;
}

.nn_share_ctn h6 {
    margin: 0;
    font-size: 20px;
    width: 100%;
    text-indent: 25px;
}

.payoutTop {
    display: flex;
    align-items: center;
}

.payoutTop h5 {
    margin: 0 0 0 15px;
}

.payoutTop button {
    margin: 0 5px 0 0;
}

.nn_link ul li:last-child a div {
    white-space: pre-wrap;
}

.Notemgs.nn_msg {
    margin-bottom: 12px;
}

.nn_flexWrap {
    flex-wrap: wrap;
}

.nn_marginTop {
    margin-top: 20px !important;
}

/*-------------------------------footer Css---------------------------------*/
body {
    margin: 0px !important;
}

.footer-outer .nn_footersecpart.footer-bg {
    background-image: url('./../../../assets/img/footre_bg.png') !important;
    background-size: cover !important;
    background-repeat: no-repeat !important;
}

.nn_ftaboutctn ul {
    column-count: 1 !important;
}

.footer-outer .footer-bg .footer-bgspace {
    padding-top: 100px !important;
    padding-bottom: 15px !important;
}

.footerTittle {
    color: #fff;
    font-size: 22px;
    margin-bottom: 34px;
    font-weight: 700;
}

.footer-pera.footer-pera2 .pera {
    color: rgba(255, 255, 255, .7);
    margin-bottom: 28px;
}

.mt-10 {
    margin-top: 10px;
}

.footer-form {
    position: relative;
    z-index: 1;
}

.mb-20 {
    margin-bottom: 20px;
}

.mb-40 {
    margin-bottom: 40px;
}

.form-control input {
    border: 1px solid rgba(255, 255, 255, .2);
    background: 0 0;
    color: rgba(255, 255, 255, .7);
}

.newsletter .social-icon {
    color: rgba(255, 255, 255, .7);
}

.footer-social .listing .listItem {
    margin-bottom: 28px !important;
}

.listing {
    margin: 0;
    padding: 0;
}

.listing .listItem {
    margin-bottom: 10px;
}

.listing .listItem .singleLinks {
    color: rgba(255, 255, 255, .7);
    font-size: 16px;
    align-items: center;
    display: flex;
    transition: .3s;
    font-weight: 500;
}

.listing .listItem .singleLinks .icon {
    color: #fff;
    background: rgba(255, 255, 255, .3);
    transition: .4s;
    display: inline-block;
    text-align: center;
    margin-right: 7px;
    width: 40px;
    height: 40px;
    line-height: 40px;
    border-radius: 50%;
    font-size: 20px;
}

.listing .listItem .singleLinks:hover {
    color: #0f6aff;
}

.listing .listItem .singleLinks:hover .icon {
    background: #0f6aff;
    color: #fff;
}

.footerTittle {
    color: #fff;
    font-size: 20px;
    margin-bottom: 34px;
    font-weight: 500;
}

.footer-category-list {
    display: flex;
    column-gap: 45px;
}

.newsletter .social-icon {
    transition: .4s;
    display: inline-block;
    font-size: 14px;
}

.social-icon:hover {
    -webkit-transform: translateY(-5px);
    transform: translateY(-5px);
    color: rgb(15 106 255) !important;
}

.copyright p {
    color: rgba(255, 255, 255, .7) !important;
    font-weight: 400 !important;
    font-size: 16px !important;
    line-height: 2 !important;
    margin: 0 !important;
}

.nn_ftdropdown {
    padding-top: 0px !important;
}

.MuiInput-underline-448:before {
    border-bottom: none !important;
}

.MuiInput-focusde-446:focus-visible {
    outline: none !important;
}

.downloada_app {
    margin-top: 90px;
}

.download_appcnt p {
    font-size: 55px;
    font-weight: 600;
    color: #333333;
    margin-bottom: 25px;
    line-height: 1.6;
}

.free-app {
    color: #1f62fd !important;
}

.download_appcnt span {
    color: #f205e4;
}

.mb-20 {
    margin-bottom: 20px;
}

/* .news-input{
    width: 100%;
    height: 52px;
    padding: 10px 20px;
    padding-right: 101px;
    border: 1px solid rgba(255, 255, 255, .2);
    background: 0 0;
    border-radius: 8px;
} */

.newsletter-footer input {
    border-radius: 8px !important;
    width: 100% !important;
    height: 52px !important;
    padding: 10px 20px !important;
    padding-right: 101px !important;
    background: 0 0 !important;
    border: 1px solid rgba(255, 255, 255, .2) !important;
    background: 0 0 !important;
    color: rgba(255, 255, 255, .7) !important;
}

.newsletter-footer input::placeholder {
    color: rgba(255, 255, 255, .7) !important;
}

.form-row {
    width: 100%;
    display: contents;
}

.newsletter-footer .append {
    position: absolute;
    right: 5px;
    top: 5px;
    height: 42px;
    border-radius: 8px;
    cursor: pointer;
    padding: 10px 20px;
    font-size: 16px;
    /* background-image: linear-gradient(to right, #0f6aff, #f902e3) !important; */
    background-color: white;
    color: #000;
    font-weight: 600;
    line-height: 1;
    border: 0;

    display: flex;
    align-items: center;
}

.newsletter-footer .append .form-group {
    margin-bottom: 0px !important;
}

.nn_footersecpart .nn_footersecctn {
    padding: 100px 0px 75px !important;
}

.nn_footermain.footer-top-height {
    height: 100% !important;
}

.footertopimg {
    position: inherit !important;
    left: inherit !important;
}

.footer-content {
    align-items: center !important;
}

.footer-space {
    padding: 0px !important;
}

/*----------------------------downloada_app-----------------*/
.nn_footerfirstpart {
    box-shadow: none !important;
}

.nn_footer .nn_ftmain {
    padding-bottom: 0px !important;
}

h1.nn_getfreeapp {
    font-size: 55px !important;
    font-weight: 600 !important;
    color: #333333 !important;
    margin-bottom: 25px !important;
}

/*--------------------------------Header----------------------------*/
.header-bg .headermain {
    background-image: linear-gradient(to right, #0f6aff, #f902e3) !important;
}

.NavWrapper {
    display: flex;
    align-items: center;
    width: 100%;
    text-align: center;
}

.NavWrapper .collapse:not(.show) {
    display: block !important;
}

.NavWrapper ul.navbar-nav {
    display: block;
    width: 100%;
    margin-left: 25px;
}

.NavWrapper ul.navbar-nav li {
    display: inline-block;
    font-size: 16px;
    font-family: var(--body-font);
    text-transform: capitalize;
    color: #344054 !important;
    font-weight: 400;
    line-height: 70px;
}

.navbar-nav li a {
    color: #fff;
    transition: all .3s ease-in;
    text-decoration: none;
    padding: 10px 19px;
}

.navbar-nav li a:hover {
    color: #f902e3;
}

.kSbPOB .menumain .menubtn {
    background-color: transparent !important;
    color: white !important;
}

.cmn-btn1 {
    transition: .4s !important;
    border: 1px solid #f902e3 !important;
    color: #0f6aff !important;
    background: white !important;
    padding: 10px 25px !important;
    font-size: 14px !important;
    font-weight: 500 !important;
    display: inline-block !important;
    border-radius: 8px !important;
}

.cmn-btn1:hover {
    background: 0 0 !important;
    border: 1px solid #ffffff !important;
    color: #ffffff !important;
}

.cmn-btn1 i {
    transition: .3s !important;

    margin-right: 10px !important;
    font-size: 22px !important;

    line-height: 1 !important;
    position: relative !important;
    bottom: -4px !important;
}

.cmn-btn1:hover i {
    color: #ffffff !important;
}

.MuiBadge-badge-149 {
    top: -7px !important;
    right: -4px !important;
    width: 15px !important;
    height: 15px !important;
    font-size: 0.65rem !important;
}

.badge-icon svg path {
    color: white;
}

.header-logo {
    width: fit-content !important;
    height: 100% !important;
}

.header-logo .logoimg {
    width: 135px !important;
}

.header-container {
    display: flex !important;
    justify-content: space-between;
    align-items: center;
}

.header-langugae {
    margin: 0px 10px !important;
}

.header-langugae .form-group {
    margin-bottom: 0px !important;
}

.header-langugae .form-control {
    margin-bottom: 0px !important;
    padding: 0px !important;
    height: inherit !important;
    border-radius: 0px !important;
    background: transparent !important;
    border: none !important;
    color: white !important;
}

.header-user {
    margin: 0px 10px;
}

.header-user a {
    color: white !important;
}

.add-to-cart svg {
    margin: 0px 10px !important;
    color: white !important;
}

.add-to-cart svg path {
    /* fill: white !important; */
}

/*-----------------------------------banner-----------------------------------*/
.banner-content {
    position: absolute;
    top: 50%;
    left: 50%;
    padding-left: 20px;
    padding-right: 20px;
    transform: translate(-50%, -50%);
}

.banner-outer {
    margin-top: -5px !important;
    padding-top: 0px !important;
}

.catergory-margin {
    margin-top: 110px !important
}

.banner-image {
    height: 616px;
    background-position: center;
    background-size: cover;
    position: relative;
}
.seaiconnss svg{
    fill: #fff;
    width: 24px;
    height: 24px;
}
.nn_fliterctn label{
    margin-bottom: 0px!important;
}
.MuiDialog-root  .react-stars {
    align-items: center;
    justify-content: center;
    display: flex;
    width: 100%;
}
.seaiconnss{
    background-image: linear-gradient(90deg,#0f6aff,#f902e3)!important;
    position: absolute;
    height: 100%;
    border-radius: 8px!important;
    width: 50px;
    display: flex;
    right: 0px;
    top: 0px;
    align-items: center;
    justify-content: center;
}
.searcbaricon input{
    overflow: hidden;
    padding-right: 60px;
}
.searcbaricon{
    position: relative;
}
.banner-title {
    font-size: 55px;
    color: #000;
    font-weight: 700;
    margin-bottom: 29px;
    line-height: 1.3;
    text-align: center;
}

.banner-pera {
    color: #fff;
    font-size: 20px;
    font-weight: 400;
    margin-bottom: 50px;
    text-align: center !important;
}

.search-box {
    background: #fff;
    padding: 20px;
    padding-bottom: 0;
    border-radius: 8px;
}

.search-form {
    width: 23%;
}

.search-form .search-Btn {
    color: #fff;
    font-family: var(--heading-font);
    font-size: 16px;
    font-weight: 500;
    margin-bottom: 20px;
    width: 100%;
    height: 50px;
    background-image: linear-gradient(to right, #0f6aff, #f902e3);
    line-height: 1px;
    text-align: center;
    display: block;
    padding: 15px;
    border-radius: 8px;
    text-transform: capitalize;
    border: 0;
}

.bAcImv .postbtn .ptbtn, .bAcImv .nn_loadmore .nn_loadbtn {
    background-image: linear-gradient(to right, #0f6aff, #f902e3) !important;
    color: white !important;
    border-radius: 8px !important;
}

.MuiInput-underline-395:before {
    border-bottom: none !important;
}


.form-control:focus {
    background-color: transparent !important;
    box-shadow: none !important;
}


.form-control, .form-control-input input {
    border: 1px solid #ddd !important;
    width: 100% !important;
    height: 50px !important;
    background: #fff !important;
    padding: 11px 19px 11px 19px !important;
    color: #667085 !important;
    margin-bottom: 16px !important;
    line-height: 25px !important;
    border-radius: 8px !important;
    margin-bottom: 0px !important;
    font-size: 16px !important;
    box-sizing: border-box !important;

}

.mbing-bottm {
    margin-bottom: 20px !important;
}

select.form-control, .form-control-input select {
    appearance: none !important;
    font-family: "Ubuntu", sans-serif !important;
    background: url("data:image/svg+xml,%3Csvg focusable='false' xmlns='http://www.w3.org/2000/svg' viewBox='0 0 24 24'%3E%3Cpath fill='rgba(0,0,0,.54)' d='M16.59 8.59L12 13.17 7.41 8.59 6 10l6 6 6-6z'%3E%3C/path%3E%3C/svg%3E") no-repeat 98% 40% !important;
    background-size: 7% !important;
}

.mabershipplan-login.form-control-input select {
    appearance: none !important;
    font-family: "Ubuntu", sans-serif !important;
    background: url("../../../assets/img/svg-arrow.svg") no-repeat 80% 48% !important;
    background-size: 15% !important;
}

.form-control-input.textarea-input input {
    height: 120px !important;

}

.select-itms {
    display: flex;
    align-items: center;
    justify-content: space-around;
}

.select-location {
    width: 36%;
}

.searchBox-wrapper {
    width: 38%;
}

.search-form {
    width: 23%;
}

.see_all-all {
    font-family: 'Inter';
    font-style: normal;
    font-weight: 600;
    font-size: 16px;
    line-height: 19px;
    color: #F404E4;
}

.outerbox-gigs {
    background: #FFFFFF;
    box-shadow: 0px 4px 16px 2px rgba(0, 0, 0, 0.1);
    border-radius: 15px;
    text-align: right;
}

.titles-vendor {
    font-family: 'Inter';
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 19px;
    color: #000000;
    margin-top: 10px;
}

.loginasvendor img {
    height: 55px;
    width: auto;
    max-width: unset;
}

.loginModalbootouter {
    max-width: 800px !important;
    margin: 0 auto;
    bottom: auto !important;
    border: none !important;
    position: absolute;
    border: 1px solid rgb(204, 204, 204);
    background: rgb(255, 255, 255);
    border-radius: 4px;
    outline: none;
    overflow: hidden;
    padding: 0px;
    left: 50% !important;
    top: 50% !important;
    -webkit-transform: translate(-50%, -50%);
    transform: translate(-50%, -50%);
    height: 90%;
}
.close-Id{
    position: absolute;
    left: 0px;
    top: 0px;
}
.membership-text {
    font-family: 'Inter';
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 19px;
    text-align: center;
    color: #000000;
    margin-top: 24px;
    margin-bottom: 24px;
}

/* .loginModalboot{
    left: 50%!important;
    top: 50%!important;
    transform: translate(-50%, -50%);
} */
/*-----------------------------heading----------------------------*/

.section-tittle .tittle {
    color: var(--heading-color);
    font-family: var(--heading-font);
    font-size: 36px;
    display: block;
    font-weight: 600;
    line-height: 1.4;
    margin-bottom: 18px;
    padding-left: 12px;
    display: inline-block;
    position: relative;
}

.section-tittle .tittle .shape {
    position: absolute;
    width: 72px;
    height: 50px;
    background-image: linear-gradient(to right, #0f6affab, #f902e370);
    z-index: -1;
    left: 0;
    top: 0;
    margin: 0;
    line-height: 1;
}

.loginModalboot .section-tittle .tittle .shape {
    height: 32px;
}

.loginModalboot .section-tittle .tittle {
    font-size: 22px;
}

.nn_categoryctn a img {
    height: 130px;
    width: 130px;
    display: inline-block;
    position: relative;
}

.nn_categoryctn {
    transition: .4s;
}

.nn_categoryctn a {
    color: #fff;
}

.nn_categoryctn a .cateimg {
    object-fit: cover;
    background: #fff;
    border: 1px solid #000;
}

.nn_catetitle {
    color: var(--subtheme-color) !important;
    text-decoration: none;
    font-size: 17px;
    font-weight: 600;

    margin-left: 10px;
}

.nn_catealign:hover img {
    top: 0px !important
}

.section-tittle .tittle>span {
    color: #0f6aff;
    font-size: 82px;
}

.section-tittle span {
    color: var(--main-color-two);
    font-size: 16px;
    font-weight: 400;
    display: inline-block;
    margin-bottom: 13px;
}

/*-----------------------------Recommendation--------------------------*/
.card-content {
    background: #fff !important;
    padding: 18px 20px 20px 20px !important;
    left: 0 !important;
    right: 0 !important;
    margin: 0 auto !important;
    margin-top: -30px !important;
    border-radius: 20px !important;
    -webkit-box-shadow: 0 3px 12px rgb(45 23 191 / 9%) !important;
    box-shadow: 0 3px 12px rgb(45 23 191 / 9%) !important;
    position: relative !important;
    z-index: 0 !important;
    margin-bottom: 24px !important;
    height: 225px !important;
}

.productmain .productctn .recommendation .prosection {
    height: 100% !important;
}

.product-heading {
    width: 100% !important;
}

.product-heading .main,
.product-heading .secondary {
    padding: 0px !important;
}

.product-title {
    margin-bottom: 2px !important;
    line-height: 1.5 !important;
    color: var(--heading-color) !important;
    font-weight: 500 !important;
    font-size: 22px !important;
    display: block !important;
    text-decoration: none !important;
    font-weight: 600 !important;
}

.Apollo-Apollo-Apollo-Apollo-Apollo-Apollo-Apollo-Apollo-Apollo-Apollo-Apollo-Apollo-Apollo-Apollo-Apollo-Apollo-Apollo-Apollo-Apollo-Products--------------------jVEKGa-208 {
    color: #524eb7 !important;
    font-weight: 500 !important;
    font-size: 16px !important;
    line-height: 1.6 !important;
}

.product-amount p {
    margin-bottom: 10px;
    font-family: var(--heading-font);
    color: var(--heading-color);
    font-weight: 700;
    font-size: 20px;
    display: block;

}

.productdetails {
    display: block !important;
}

.pro-btn1 {
    transition: .4s !important;
    border: 1px solid transparent !important;
    background-image: linear-gradient(to right, #0f6aff, #f902e3) !important;
    color: white !important;
    text-transform: uppercase !important;
    padding: 4px 7px !important;
    font-size: 12px !important;
    font-weight: 400 !important;
    display: inline-block !important;
    border-radius: 6px !important;
    margin-right: 6px !important;
}

.pro-btn1:hover {
    background: #0f6aff !important;
    border: 1px solid transparent !important;
    color: #fff !important;
}

.product-button button {
    min-width: inherit !important;
    min-height: inherit !important;
}

.product-button {
    display: flex !important;
}

.product-button p {
    margin-bottom: 0px !important;
    align-items: center;
}

.heart-icon {
    right: 10px !important;
}

/* .chatimg {
    width: fit-content;
    position: absolute;
    border: 0px;
    margin-top: 9px;
} */
.chatimg .nn_chatBtn span {
    display: none !important;
}

.chatimg .nn_chatBtn {
    width: 40px;
    height: 40px;
}

.chatimg .nn_chatBtn span svg path {
    fill: white;
}

.chatimg button {
    background-image: linear-gradient(to right, #0f6aff, #f902e3);
    color: white !important;
    padding: 2px 6px;
    border-radius: 5px;
}

.chatimg button svg path {
    fill: #fff;
}

.chatimg button:hover {
    background-color: #0f6aff !important;
    background-image: none !important;
}

.favicon.heart-icon:hover svg path {
    color: red;

}

label.labelName-filter {
    display: flex !important;
}

.filterNAme {
    font-size: 22px;
    color: #000;
    margin: 0px;
    font-weight: bold;

}

.cleBtmn {
    padding: 0px;
    font-weight: bold;
    color: #f603e3;
}

.filertcler {
    margin-top: 20px;
    margin-bottom: 20px;
    padding: 0px 15px;
    display: flex;
    align-items: center;
    justify-content: space-between;
}

/*---------------------------aboutArea------------------------------*/

@media (min-width: 1400px) {
    .container, .container-lg, .container-md, .container-sm, .container-xl, .container-xxl {
        max-width: 1320px;
    }
}

/* .sc-jrsJWt.bhPmEe{
    overflow: hidden !important;
} */

.section-padding {
    padding-top: 110px;
    padding-bottom: 95px;
}


/* .section-padding1 {
    padding-top: 110px;
    padding-bottom: 255px;
} */
.sectionBg1 {
    background: #f9fafb;
}

.position-relative {
    position: relative !important;
}

.mb-15 {
    margin-bottom: 15px;
}

.mb-55 {
    margin-bottom: 55px;
}

.mb-10 {
    margin-bottom: 10px;
}

.mr-15 {
    margin-right: 15px;
}

.section-tittle.section-tittle2 .tittle {
    font-size: 48px;
    line-height: 1.3;
    padding-left: 0;
}

element.style {
    visibility: visible;
    animation-delay: 0.2s;
    animation-name: fadeInUp;
}

.section-tittle.section-tittle2 p {
    color: #667085;
}

.btn-wrapper .cmn-btn2 {
    font-family: var(--heading-font);
    -webkit-transition: .4s;
    transition: .4s;
    border: 1px solid transparent;
    background-image: linear-gradient(to right, #0f6aff, #f902e3);
    color: #fff;
    padding: 13px 44px;
    font-size: 16px;
    font-weight: 600 !important;
    display: inline-block;
    text-transform: capitalize !important;
    border-radius: 8px;
    text-decoration: none;
}

.aboutArea .aboutShape.aboutShape1 {
    left: 0;
    top: 50%;
    z-index: 1;
    -webkit-transform: translateY(-50%);
    transform: translateY(-50%);
}

.aboutArea .aboutShape {
    position: absolute;
}

.aboutArea .aboutShape>img {
    width: auto;
}

.bouncingAnimation {
    -webkit-animation: bouncebubble infinite 5s;
    animation: bouncebubble infinite 5s;
}

.aboutArea .aboutShape.aboutShape2 {
    right: 0;
    top: 50%;
    z-index: 1;
    -webkit-transform: translateY(-50%);
    transform: translateY(-50%);
}

@-webkit-keyframes bouncebubble {
    1% {
        -webkit-transform: translateY(0);
        transform: translateY(0)
    }

    25% {
        -webkit-transform: translateY(-10px);
        transform: translateY(-10px)
    }

    50% {
        -webkit-transform: translateX(10px);
        transform: translateX(10px)
    }

    75% {
        -webkit-transform: translateX(-10px);
        transform: translateX(-10px)
    }

    100% {
        -webkit-transform: translateY(0);
        transform: translateY(0);
        -webkit-transform: translateX(0);
        transform: translateX(0)
    }
}

@keyframes bouncebubble {
    1% {
        -webkit-transform: translateY(0);
        transform: translateY(0)
    }

    25% {
        -webkit-transform: translateY(-10px);
        transform: translateY(-10px)
    }

    50% {
        -webkit-transform: translateX(10px);
        transform: translateX(10px)
    }

    75% {
        -webkit-transform: translateX(-10px);
        transform: translateX(-10px)
    }

    100% {
        -webkit-transform: translateY(0);
        transform: translateY(0);
        -webkit-transform: translateX(0);
        transform: translateX(0)
    }
}

/*----------------------------------recent listing---------------------------------*/

.plr {
    padding-left: 135px;
    padding-right: 135px;
}

.mb-50 {
    margin-bottom: 40px !important;
}

.recentListing .borderStyle {
    position: relative;
    background: 0 0;
    background: #fff;
    margin-bottom: 20px;
}

.recentListing .style1::before {
    left: 50%;
    top: 0;
    -webkit-transition-duration: .4s;
    transition-duration: .4s !important;
}

.recentListing .borderStyle::before, .recentListing .borderStyle::after {
    -webkit-transition: .4s;
    transition: .4s;
    content: "";
    width: 0;
    height: 1px;
    position: absolute;
    background: var(--main-color-two);
}

.recentListing .style1:hover::before, .recentListing .style1:hover::after {
    left: 0;
}

.recentListing .borderStyle:hover::before, .recentListing .borderStyle:hover::after {
    width: 100%;
}

.recentListing .singleFlexitem {
    background: #fff;
    -webkit-box-shadow: 0 1px 80px 12px rgb(26 40 68 / 6%);
    box-shadow: 0 1px 80px 12px rgb(26 40 68 / 6%);
    padding: 20px;
    border-radius: 12px;
    display: -webkit-box;
    margin-bottom: 0px;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    -webkit-transition: .4s;
    transition: .4s;
    cursor: pointer;
    left: auto;
}

.mb-24 {
    margin-bottom: 24px;
}

.recentListing .style1 .singleFlexitem::before {
    left: 0;
    top: 50%;
    border-radius: 12px;
    -webkit-transition-duration: .4s;
    transition-duration: .4s !important;
}

.recentListing .borderStyle .singleFlexitem::before, .recentListing .borderStyle .singleFlexitem::after {
    content: "";
    width: 1px;
    height: 0;
    border-radius: 12px;
    position: absolute;
    -webkit-transition: all .2s linear;
    transition: all .2s linear;
    background: var(--main-color-two);
    border-radius: 5px;
}

.recentListing .singleFlexitem .recentImg {
    margin-right: 20px;
}

.recentListing .singleFlexitem .recentImg img {
    border-radius: 12px;
    width: 100px;
    height: 100px;
    object-fit: cover;
}

.recentCaption h5 {
    margin-bottom: 0;
    font-weight: 700;
    color: black !important;
    font-size: 22px;
}

.recentListing .singleFlexitem .recentCaption .featureCap {
    font-family: var(--heading-font);
    font-size: 15px;
    color: var(--heading-font);
    margin-bottom: 2px;
    line-height: 1.6;
    margin-bottom: 0;
    font-weight: 400;
}

.recentListing .singleFlexitem .recentCaption .featureCap .subCap {

    color: #524eb7;
    font-weight: 600;
}

.recentListing .singleFlexitem .recentCaption .featurePricing {
    margin-bottom: 18px;
    font-family: var(--heading-font);
    color: var(--heading-color);
    font-weight: 600;
    font-size: 20px;
    display: block;
}

a {
    color: #635c5c;
    text-decoration: none !important;
}

/*-------------------------------------category----------------------------------*/
.nn_category {
    position: relative !important;
}

.catergory-margin {
    padding: 90px 0px !important;
    margin: 0px !important;
}

.nn_categorylist {
    background-color: transparent !important;
}

.nn_categorylist.active {
    padding: 0px !important;
}

.nn_categorylist.active .cls_licount {
    padding: 0px !important;
}

.cls_licount {
    display: grid !important;
    grid-template-columns: 20% 20% 20% 20% 20%;
}

.nn_catealign {
    display: block !important;
    align-items: center;
    justify-content: center;
    margin: 0px auto 40px !important;
    text-align: center !important;

    width: 100% !important;
}

.nn_categoryctn {
    margin-bottom: 25px !important;
}

.nn_catealign {
    text-align: center;
}

a.nn_catetitle.text-truncate.d-block {
    margin-left: 0px !important;
    color: #333333 !important;
    font-size: 28px !important;
    line-height: 1.4 !important;
    margin-bottom: 6px !important;
    font-weight: 500 !important;
    display: block !important;
}

.nn_categoryctn {
    transition: .5s !important;
}

/* .nn_catealign:hover img {
    top: 0px !important;
} */
.nn_catealign:hover a.nn_catetitle.text-truncate.d-block {
    color: #0f6aff !important;
}

.nn_btn.cls_left.hidden, .nn_btn.cls_right.active {
    display: none !important;
}

.category-icon {
    border: none !important;
}

.category-icon img {
    width: 64px !important;
    height: 64px !important;
}

/*--------------------------copyright-----------------------*/
.copyright .nn_ftpara svg {
    filter: invert(1);
}

.copyright a {
    color: rgba(255, 255, 255, .7) !important;
}


/*-------------------------login sidebar--------------------*/
.login-sidebar .login-sidebar-header {
    background-image: linear-gradient(to right, #0f6aff, #f902e3) !important;
    text-align: center;
}

.login-sidebar .login-sidebar-header img {
    margin: auto !important;
}

/* .sidebar-menu a.sideMenu{
    color: black !important;
} */


.nn_catealign {
    position: relative;
    left: 0px !important;
    background: transparent !important;
    /* right: 0px !important; */
}

.cls_licount.nn_licount1 .nn_selectCate.nn_catealign {
    left: 0px !important;
}

.prosection {
    height: 100% !important;
}

/* .nn_selectCate.nn_catealign{
    display: none !important;
} */
/*-------------------------------------contact us----------------------------------*/
.section-padding2 {
    padding-top: 80px;
    padding-bottom: 120px;
}

.contact-outer {
    width: 100% !important;
}

label, .label {
    margin-bottom: 11px !important;
    font-size: 16px !important;
    font-weight: 600 !important;
    display: block !important;
    color: black !important;
}


.form-control-input div:first-child div::after {
    border: none !important;
}


.form-control-input .CustomInput-underline-299:hover:not(.CustomInput-disabled-298):before, .CustomInput-underline-299:before {
    display: none !important;
}

.form-control-input .CustomInput-formControl-305 {
    padding-top: 0px !important;

    margin-bottom: 0px !important;
}

.contact-img {
    width: 100% !important;
}


/*---------------------------------about  us--------------------------------*/
.nn_service {
    margin-top: 100px !important;
}

.about-para {
    font-size: 20px !important;
    font-weight: 500 !important;
    color: #667085 !important;
    line-height: 25px !important;
}

.form-control-input .MuiInputAdornment-positionStart-407 {
    margin-right: 8px !important;
    position: absolute !important;
    top: 9px !important;
    left: 15px !important;
}

.CustomInput-formControl-373 {
    margin: 0px !important;
}

.nn_email:before, .nn_password:before, .nn_fullmame::before {
    display: none !important;
}

.login-input.form-control-input input {
    /* padding: 11px 19px 11px 44px !important; */
    height: 40px !important;
}

.login-input.form-control-input button {
    top: 44px !important;

}

.login-heading .shape {
    z-index: 9 !important;
}

.login-heading .tittle {
    margin-bottom: 0px !important;
}

.nn_email:after, .nn_password:after, .nn_fullmame:after {
    display: none !important;
}

.forgot-pass p {
    color: #667085;
    font-weight: 400;
    line-height: 1.6;
    font-size: 16px;
}

.auth-hedaing {
    display: flex;
    width: 100%;
    flex-direction: inherit !important;
    align-items: center !important;
    justify-content: flex-start !important;
    text-align: left !important;
}

.RegularButton-sm-109 {

    opacity: 1 !important;
}

.RegularButton-sm-109.forgot-button {
    padding: 0px 1vmin;
}

.CustomInput-formControl-373 {
    padding: 0px !important;
}

.MuiButton-label-115 {
    display: block !important;
}

.fogpg.forgot-pass {
    margin-top: 20px !important;
    display: block !important;
}

/* .login-facebook,.login-google{
    padding:0px 15px !important;
    width: fit-content !important;
    margin: auto;
} */
/* .login-facebook img,.login-google img{
    margin-right: 20px !important;
} */
.login-submit-input button {
    margin-bottom: 20px !important;
}

.btn-wrapper .cmn-btn-auth {
    font-family: var(--heading-font);
    transition: .4s;
    border: 1px solid transparent;
    background-image: linear-gradient(to right, #0f6aff, #f902e3);
    color: #fff;
    padding: 8px 34px;
    font-size: 16px;
    font-weight: 500;
    display: inline-block;
    border-radius: 8px;
    text-decoration: none;
}

.login-google {
    margin-top: 0px !important;
}

.other-account ul {
    display: flex !important;
    justify-content: center !important;
}

.other-account ul li {
    margin: 0px !important;

}


/*--------------------------------porduct details--------------------------*/
.breadcrumb {
    background: white !important;
    margin: 20px 0px 50px !important;
    padding: 0px !important;
}

.chatname-bg {
    width: 100%;
    color: #fff;
    padding: 0px 10px;
    font-weight: bold;
    background-image: linear-gradient(to right, #0f6aff, #f902e3) !important;
}

.buynowbutton {
    border: 1px solid #f902e3 !important;
}

.seller_msg_button {
    justify-content: flex-start !important;
}

.details_oUter {
    padding-left: 20px;
}

.details_oUter .product-right {
    padding-left: 0px !important;
    max-height: 100% !important;
    overflow-y: auto !important;
    float: none !important;

}

.bottom-chat {
    position: unset !important;
    top: auto;
    margin-top: 15px;
}

.butonChat {
    background-color: var(--theme-color);

}

/* .loginModalboot{
    top: 50%;
    transform: translate(-50%, -50%);
    left: 50%;
} */
.inputpaddingremove {

    padding-top: 0px !important;
    margin-bottom: 0px !important;
}


.favorite-icon-white {
    filter: unset !important;
}

.footer-category-list ul.listing {
    column-count: 2;
}

li.nn_catealign {
    padding-bottom: 40px !important;
    padding-top: 40px !important;
    border-bottom: 1px solid #eaecf0;
    width: 20%;
    margin-bottom: 0px !important;
}

li.nn_catealign::after {
    content: "";
    position: absolute;
    right: 0;
    top: 50%;
    width: 1px;
    background-color: #eaecf0;
    height: 100%;
    -webkit-transform: translateY(-50%);
    transform: translateY(-50%);
}

li.nn_catealign:last-child::after {
    display: none
}

.nn_categoryctn .closecate {
    position: absolute;
    top: 20px;
    right: -30px;
}

.nn_categoryctn a .closecate img {
    height: 20px !important;
    width: 20px !important;
}

.explore {
    padding: 90px 0px 50px;
}

.onlycategries li.nn_catealign:nth-child(6),
.onlycategries li.nn_catealign:nth-child(7),
.onlycategries li.nn_catealign:nth-child(8),
.onlycategries li.nn_catealign:nth-child(9),
.onlycategries li.nn_catealign:nth-child(10) {
    border-bottom: 0px !important;
}

.chat-iconouter {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-top: 8px;
}

a.buttonfoot {
    background: transparent;
    color: #fff;
    padding: 6px;
    font-size: 14px;
    font-weight: bold;
    border: 1px solid #ffff;
    border-radius: 8px;
    width: 120px;
    text-align: center;
    display: flex;
    align-items: center;
    justify-content: center;
    column-gap: 6px;
}

.buttonfoot svg {
    width: 20px;
    height: 20px;
}

.footer-inner {
    padding-bottom: 60px;
}

.quicklink {
    display: flex;
    align-items: center;
    column-gap: 12px;
    row-gap: 12px;
    flex-wrap: wrap;
}

.productctn {
    display: flex;
    align-items: flex-start;
}

.catepro-outer {
    padding-top: 50px;
    padding-bottom: 50px;
}

.about-para h2 {
    font-size: 22px !important;
    margin: 35px 0px 18px !important;
    color: #000 !important;
    font-weight: bold !important;
}

.about-para .headingterms, .about-para .priourpg p {
    color: #667085 !important;
    font-size: 16px !important;
    font-weight: 400 !important;
}

.about-para .headingterms span, .about-para .priourpg p span {
    color: #667085 !important;
    font-size: 16px !important;
    font-weight: 400 !important;
}

.checkWrap2 {
    font-size: 14px !important;
    color: #757575 !important;
    font-family: var(--heading-font);
    display: block;
    position: relative;
    margin-bottom: 25px !important;
    cursor: pointer;
    margin-top: 15px !important;
    line-height: 25px;
}

.socialLoginouter ul {
    display: flex;
    align-items: center;
    justify-content: center;
}

.checkbox_box {
    height: auto !important;
    margin-right: 10px !important;
    width: fit-content !important;
}

.loginRightpadding {
    padding: 0px 20px;
}

.proDescription {
    background: #fff;
    padding: 35px 20px 20px 20px;
    background: #fff;
    -webkit-box-shadow: 0 1px 80px 12px rgb(26 40 68 / 6%);
    box-shadow: 0 1px 80px 12px rgb(26 40 68 / 6%);
    border-radius: 0px 0px 12px 12px;
}

.proDescription .descriptionTop {
    border-bottom: 1px solid #eaecf0;
    margin-bottom: 32px;
    padding-bottom: 30px;
}

.proDescription .descriptionTop .detailsTittle {
    font-family: var(--heading-font);
    line-height: 1.5;
    color: var(--heading-color);
    font-weight: bold;
    font-size: 32px;
    display: block;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: justify;
    -ms-flex-pack: justify;
    justify-content: space-between;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
}

.proDescription .descriptionMid .priceTittle {
    -webkit-transition: .4s;
    transition: .4s;
    font-family: var(--heading-font);
    color: var(--heading-color);
    font-size: 24px;
    font-weight: bold;
    margin-bottom: 10px;
}

.btn-wrapper .cmn-btn-outline2::before {
    border: 1px solid transparent;
    content: "";
    position: absolute;
    left: 0;
    top: 0;
    width: 101%;
    height: 101%;
    background-image: linear-gradient(to right, #0f6aff, #f902e3);
    z-index: -1;
    -webkit-transition: -webkit-transform .5s;
    transition: -webkit-transform .5s;
    transition: transform .5s;
    transition: transform .5s, -webkit-transform .5s;
    -webkit-transition-timing-function: ease;
    transition-timing-function: ease;
    -webkit-transform-origin: 0 0;
    transform-origin: 0 0;
    -webkit-transition-timing-function: cubic-bezier(.5, 1.6, .4, .7);
    transition-timing-function: cubic-bezier(.5, 1.6, .4, .7);
    -webkit-transform: scaleX(0);
    transform: scaleX(0);
    border-radius: 0;
}

.btn-wrapper .cmn-btn-outline2 {
    font-family: "Ubuntu", sans-serif;
    font-family: var(--heading-font);
    border: 1px solid #d0d5dd;
    color: #667085;
    font-size: 15px;
    font-weight: bold;
    text-transform: normal;
    padding: 14px 15px !important;
    align-items: center;
    text-align: center;
    -moz-user-select: none;
    cursor: pointer;
    display: inline-block;
    position: relative;
    transition: color .4s linear;
    position: relative;
    overflow: hidden;
    border-radius: 8px;
    background: 0 0;
    z-index: 1;
    width: 100%;
    min-width: 200px;
}

.singleFlexitem .recentCaption .featureCap .subCap {
    font-family: var(--heading-font);
    font-family: var(--heading-font);
    color: var(--main-color-two);
    font-weight: 400;
}

.btn-wrapper .cmn-btn-outline2:hover {
    color: #fff;
}

.btn-wrapper .cmn-btn-outline2:hover::before {
    -webkit-transform: scaleX(1);
    transform: scaleX(1);
    border: 1px solid transparent;
}

.singleFlexitem {
    background: #fff;
    -webkit-box-shadow: 0 1px 80px 12px rgb(26 40 68 / 6%);
    box-shadow: 0 1px 80px 12px rgb(26 40 68 / 6%);
    padding: 20px;
    border-radius: 12px;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    -webkit-transition: .4s;
    transition: .4s;
    cursor: pointer;
    left: auto;
}

.singleFlexitem .recentCaption .featureCap {
    font-family: var(--heading-font);
    font-size: 15px;
    color: var(--heading-font);
    margin-bottom: 2px;
}

.singleFlexitem .recentCaption .featureTittle {
    font-family: var(--heading-font);
    margin-bottom: 2px;
    line-height: 1.5;
    color: var(--heading-color);
    font-weight: 500;
    font-size: 22px;
    display: block;
}

.notification-innner {
    width: 360px;
    border: 0;
    padding: 0 0;
    top: 10px !important;
    border-radius: 10px;
    overflow: hidden;
    box-shadow: 0 0.5rem 1rem rgb(0 0 0 / 15%);
}

.notificationClass {}

.msg-header {
    padding: 0.8rem 1rem;
    border-bottom: 1px solid #ededed;
    background-clip: border-box;
    background: #fff;
    text-align: left;
    display: flex;
    justify-content: space-between;
    align-items: center;
    border-top-left-radius: 10px;
    margin-bottom: 0px;
    border-top-right-radius: 10px;
}

.msg-header .msg-header-title {
    font-size: 16px;
    color: #1c1b1b;
    margin-bottom: 0;
    font-weight: bold;
}

.msg-header .msg-header-clear {
    font-size: 14px;
    color: #585858;
    margin-bottom: 0;
}

.header-notifications-list {
    position: relative;
    height: 360px;
}

button.notificationClass.dropdown-toggle.btn.btn-success {
    background: transparent;
    border-color: transparent;
    padding: 0px;
    margin-right: 10px;
}

button.notificationClass.dropdown-toggle.btn.btn-success:focus {
    background: transparent;
    border-color: transparent;
    padding: 0px;
    outline: none;
    box-shadow: none;
}

.dropdown-item {
    word-break: unset;
    padding: 10px 20px;
    border-bottom: 1px solid #ededed;
    white-space: break-spaces;
}

.dropdown-item:last-child {
    border-bottom: 0px;
}

.msg-name {
    font-size: 16px;
    margin-bottom: 5px;
    font-weight: bold;
}

.msg-info {
    font-size: 14px;
    margin-bottom: 0px;
}

.imgViewall img {
    height: 60px !important;
    width: 60px !important;
}

.notificationClass.dropdown-toggle::after {
    display: none;
}

li.nn_catealign:nth-child(5)::after,
li.nn_catealign:nth-child(10)::after,
li.nn_catealign:nth-child(15)::after,
li.nn_catealign:nth-child(20)::after {
    background-color: transparent;
    width: 0
}

.close-btn .closeicon svg path {
    color: #ef06e4;
}

.close-btn {
    position: relative;
}

.close-btn .closeicon {
    position: absolute;
    right: 0px;
    top: 10px;
}

.noMsgYet {
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 15px;
}

.about-caption2 .btn-wrapper {
    display: flex;
    grid-column-gap: 15px;
    -webkit-column-gap: 15px;
    column-gap: 15px;
    justify-content: center;
    flex-wrap: wrap;
}

.about-caption2 .btn-wrapper .cmn-btn2 {
    margin-bottom: 0px !important;
    margin-right: 0px !important;
    margin-top: 0px !important;
}

.thumbnail-slider-wrap {
    padding: 10px;
}

.thumbnail-slider-wrap .slick-slide img {

    width: 100% !important;
    height: 100px !important;
    object-fit: cover !important;
}

.thumbnail-slider-wrap .slick-slide {
    padding: 0 5px;
}

.nn_pro_chatbtn.nn_buybtn.btn-wrapper {
    display: flex;
    column-gap: 13px;
}

/*--------------------------------------responsive---------------------------------------*/
@media (max-width:1400.98px) {
    .header-container {
        padding-left: 0px;
        padding-right: 0px;
    }
}

@media (max-width:1199px) {

    .NavWrapper ul.navbar-nav {
        margin-left: 0px;
    }

    .plr {
        padding-left: 0px;
        padding-right: 0px;
    }
}

@media (max-width:1024px) {
    .navbar-nav li a {
        padding: 10px 10px;
    }

    .nn_categoryctn a img {
        height: 60px;
        width: 60px;

    }

    .banner-content, .width100 {
        width: 100%;
        max-width: 100%;
    }

    a.nn_catetitle.text-truncate.d-block, .product-title {
        font-size: 20px !important;
    }
}

/* 
@media only screen and (min-width:992px) and (max-width:1199px) {
	li.nn_catealign:nth-of-type(4n)::after {
		background-color: transparent
	}
} */
/* 
@media only screen and (min-width:768px) and (max-width:991px) {
	li.nn_catealign:nth-of-type(3n)::after {
		background-color: transparent
	}
} */
/* 
@media only screen and (min-width:576px) and (max-width:767px) {
    li.nn_catealign:nth-of-type(2n)::after {
		background-color: transparent
	}
} */
.nn_pro_chatbtn.nn_buybtn.btn-wrapper {
    display: flex;
    column-gap: 13px;
    row-gap: 15px;
}

@media screen and (max-width:991.98px) {
    .container {
        max-width: 100%;
    }

    .section-padding {
        padding-top: 50px;
        padding-bottom: 50px;
    }

    .width100 {
        max-width: 100% !important;
        width: 100% !important;
        flex: 100% !important;
    }

    .recentListing.section-padding.plr {
        padding-bottom: 0px;
    }

    .nn_footerfirstpart {
        margin-top: 0px !important;
    }

    .aboutArea .aboutShape.aboutShape2, .aboutArea .aboutShape.aboutShape1 {
        display: none;
    }

    .section-tittle.section-tittle2 .tittle br {
        display: none;
    }

    .nn_footermain1 {
        display: none;
    }

    .banner-title br {
        display: none;
    }

    .banner-image .col-md-12.col-8 {
        width: 100%;
        flex: 100%;
        max-width: 100%;
    }

    .NavWrapper {
        display: none !important;
    }

    .lJgYR .banner {
        height: inherit !important;
    }

    .contact-img {
        display: none !important;
    }

    .section-padding2 {
        padding-top: 120px !important;
        padding-bottom: 50px !important;
    }
}

@media(max-width:575px) {
    li.nn_catealign::after {
        background-color: transparent
    }

    .select-itms {
        flex-wrap: wrap;
    }

    .select-location, .searchBox-wrapper, .search-form {
        width: 100%;
    }

    .select-location .form-group, .searchBox-wrapper .form-group, .search-form .search-Btn {
        margin-bottom: 10px;
        margin-top: 0px;
    }
}

@media(min-width:1399.99px) {


    li.nn_catealign:nth-child(21) {
        border-bottom: none;
        border-right: 1px solid #eaecf0;
    }
}

@media only screen and (min-width:1200px) and (max-width:1399.99px) {

    li.nn_catealign:nth-child(5)::after,
    li.nn_catealign:nth-child(10)::after,
    li.nn_catealign:nth-child(15)::after,
    li.nn_catealign:nth-child(20)::after {

        background-color: transparent;
        width: 0
    }

}



@media (max-width:991px) {
    li.nn_catealign:nth-of-type(4n)::after {
        background-color: transparent
    }

    .cls_licount {
        display: grid !important;
        grid-template-columns: 25% 25% 25% 25%;
    }

    .mboilehidee {
        display: flex !important;
    }
}

@media only screen and (max-width:767px) {
    li.nn_catealign {
        border: thin solid #eaecf0 !important;
        border-bottom: thin solid #eaecf0 !important;
    }
    .priourpg.nn_service .section-padding2{
        padding-top: 50px;
        padding-bottom: 50px;
    }
    .onlycategries li.nn_catealign:nth-child(6), .onlycategries li.nn_catealign:nth-child(7), .onlycategries li.nn_catealign:nth-child(8), .onlycategries li.nn_catealign:nth-child(9), .onlycategries li.nn_catealign:nth-child(10) {
        border-bottom: thin solid #eaecf0 !important;
    }

    li.nn_catealign::after {
        display: none !important;
    }

    .nn_mob_searchbox {
        display: none;
    }

    .banner-image {
        height: auto;
    }

    .banner-content {
        position: unset;
        padding-left: 20px;
        padding-right: 20px;
        -webkit-transform: none;
        transform: none;
        padding-top: 70px;
        padding-bottom: 80px;
    }

    .nn_pro_chatbtn.nn_buybtn.btn-wrapper {
        display: flex;
        column-gap: 13px;
        flex-wrap: wrap;
        row-gap: 15px;
    }
}


@media only screen and (max-width:576px) {
    .banner-title, .download_appcnt p {
        font-size: 40px;
        margin-bottom: 20px;
        line-height: normal;
    }

    .cls_licount {
        display: grid !important;
        grid-template-columns: 50% 50%;
    }

    .nn_pro_share ul {
        text-align: right;
        margin-top: 20px;
    }

    .about-caption2 .btn-wrapper .cmn-btn2 {
        margin-bottom: 10px !important;
        margin-top: 0px !important;
        padding: 10px 20px;
        width: 100%;

    }


}

@media only screen and (max-width:400px) {
    .banner-title, .download_appcnt p {
        font-size: 30px;
    }

}

a.buttonfoot:hover {
    background: #7c63f1 !important;
}

.addvertinghere {
    margin-top: 40px;
    margin-bottom: 40px;
}

.chatname-bg:hover {
    background-image: linear-gradient(to right, #f902e3, #0f6aff) !important;
}

.descriptionMid p {
    word-break: break-all;
}

.descriptionMid p.pera {
    word-break: break-all;
}

.pay_now_btn {
    color: #fff !important;
}

.payemtnpaypalwind .payment_wrapper {
    position: relative;
}

.payemtnpaypalwind button.cxlosepay {
    position: absolute;
    right: 0px;
    top: 0px;
    width: 35px;
    background: #e71de1;
    color: #fff;
    border-radius: 0px 0px 0px 0px;
    line-height: 35px;
    height: 35px;
}

.payemtnpaypalwind button.cxlosepay svg {
    width: 25px !important;
    padding: 0;
    fill: rgb(255, 255, 255);
}


.nn_inner-chat {
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.nn_inner-chat button.nn_bttn {
    background: #3a57fa;
    color: #fff;
    padding: 7px 8px;
    border-radius: 6px;
}

ul.vendorOutercat {
    display: -ms-flexbox;
    display: flex;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
    margin-right: -15px;
    margin-left: -15px;
    width: 100%;
}

li.vendorOutercatlist h3 {
    font-family: 'Inter';
    font-style: normal;
    font-weight: 400;
    font-size: 20px;
    line-height: 24px;
    color: #333333;
    margin-top: 20px;
}

li.vendorOutercatlist {
    padding-left: 15px;
    padding-right: 15px;
    flex: 20%;
    max-width: 20%;
}

.nn_category-vendor {
    display: block !important;
}

li.vendorOutercatlist img {
    width: 120px;
    height: 120px;
    object-fit: cover;
    object-position: center;
    border-radius: 100px;
}

.gigs-cat-outer {
    background-color: #fff;
    box-shadow: 0px 1px 10px 0px rgba(0, 0, 0, 0.12);
    border-radius: 20px 20px;
    overflow: hidden;
    height: 100%;
}

.gigs-cat-outer .nn_homproductctn {
    height: 230px;
    width: 100%;
    border-radius: 8px 8px 0px 0px;
    display: flex;
    align-items: center;
    justify-content: center;
}

.gigs-cat-outer .nn_homproductctn img {
    width: 100%;
    border-radius: 8px 8px 0px 0px;
    object-fit: cover;
}

.gigs-cat-outer .allprodetails.card-content {
    padding: 15px !important;
    padding-bottom: 20px !important;
    margin-bottom: 0px !important;
    height: 100% !important;
}

.gigs-cat-outer .allprodetails.card-content p {
    font-family: 'Inter';
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 19px;
    color: #333333;
    margin-bottom: 15px;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    overflow: hidden;
}
.inner-catgigstitle .d-flex.align-items-center  img{
    width: 40px;
    height: 40px;
    object-fit: cover;
}
.inner-catgigstitle .d-flex.align-items-center {
    width: 100%;
}
.inner-catgigstitle h4 {
    font-family: 'Inter';
    font-style: normal;
    font-weight: bold;
    font-size: 16px;
    margin-bottom: 0px;
    line-height: 19px;
    color: #333333;
}

.catefullpages li.vendorOutercatlist {
    margin-bottom: 50px;
}

.inner-catgigstitle {
    display: flex;
    justify-content: space-between;
    margin-bottom: 15px;
}

.inner-catgigstitle h6 {
    font-family: 'Inter';
    font-style: normal;
    font-weight: bold;
    font-size: 16px;
    line-height: 19px;
    text-align: right;
    color: #333333;

}

.car-gigss {
    text-align: center;
    background: #FFFFFF;
    box-shadow: 0px 0px 56px 10px rgba(0, 0, 0, 0.07);
    border-radius: 20px;
    padding: 10px 20px 20px;
    border: 2px solid #fff;
}

.car-gigss h5{
    margin-bottom: 10px;
font-family: 'Inter';
font-style: normal;
font-weight: 500;
font-size: 16px;
line-height: 19px;
text-align: center;
color: #000000;
}
.car-gigss img{
    height: 100px;
    width: auto;
    margin-top: 25px;
    margin-bottom: 15px;
}
.car-gigss p{
font-family: 'Inter';
font-style: normal;
font-weight: 500;
font-size: 14px;
line-height: 22px;
text-align: center;
color: #807B7B;
margin-bottom: 0px;
}
 .nn_bredslash svg path {
    color: rgb(102, 112, 133);
}
.nn-chhting {
    width: 100%;
    color: #fff;
    padding: 12px 10px;
    font-weight: bold;
    background-image: linear-gradient(to right, #0f6aff, #f902e3);
    border-radius: 8px;
}
.nn-chhting:hover, .contact-btn:hover{
    background-image: linear-gradient(to right, #f902e3, #0f6aff);
    color: #fff;

}
.reviewpage{
    box-shadow: 0px 1px 10px 1px rgba(0,0,0,0.12);
    border-radius: 5px;
    padding: 20px 20px 30px;
}
.contact-btn{
    border-radius: 8px;
    width: 100%;
    color: #fff;
    padding: 10px 40px;
    font-weight: bold;
    margin-top: 20px;
    display: block;
    width: max-content;
    background-image: linear-gradient(to right, #0f6aff, #f902e3);
}
.reviewpageinner{
    display: flex;
    column-gap: 12px;
}
.reviewpageinner img{
    width: 60px;
    height: 60px;
    object-fit: cover;
}
.reviewpageinner h4 {
    /* line-height: normal; */
    margin-bottom: 2px;
    font-family: Inter;
    font-size: 20px;
    font-weight: 400;
    line-height: 27px;
    letter-spacing: 0em;
    text-align: left;
}
.reviewpageinner  p{
    font-family: Inter;
    font-size: 18px;
    font-weight: 300;
    line-height: 27px;
    letter-spacing: 0em;
    text-align: left;
    color: #9794A7;
    margin-top: 3px;
}
.nnnn_adfilter{
    width: 320px;
    position: sticky;
    top: 0px;
    z-index: 9;
    left: 0px;
    border-width: 1px;
    border-style: solid;
    border-color: rgb(238, 238, 238);
    overflow-x: hidden;
    max-height: 635px;
    padding-left: 12px;
    padding-right: 12px;
    background: rgb(255, 255, 255);
    box-shadow: rgba(26, 40, 68, 0.06) 0px 1px 80px 12px;
    border-radius: 12px;
    padding-bottom: 30px !important;
}
.right-vendro_filter1 {
    width: calc(100% - 320px);
    padding-bottom: 110px;
    padding-left: 20px;
}
.nnnn_adfilter .nn_fliterctn {
    padding: 10px 15px;
    position: relative;
}
.nnnn_adfilter .nn_fltproctn {
    display: block;
    font-size: 16px;
    text-transform: capitalize;
    color: rgb(0, 0, 0);
    font-weight: 600;
    padding-bottom: 5px;
}
.nnnn_adfilter .inpydeanrr {
    margin: 0px auto;
    border-bottom: 1px solid rgb(238, 238, 238);
    padding: 20px 25px;
    width: 100%;
}
.border-topB {
    border-top: 1px solid rgb(238, 238, 238);
    padding: 15px 0px 0px;
}
.nnnn_adfilter .nn_adlabel {
    color: var(--theme-color) !important;
    fill: var(--theme-color) !important;
}
.nnnn_adfilter  .input-range__label-container {
    color: var(--theme-color);
    font-weight: bold;
    font-family: inherit !important;
}
.blog-item {
    position: relative;
    background-color: rgba(150, 144, 162, 0.06);
    padding: 15px;
}
.blog-item figure.image-holder{
    padding-bottom: 80%;
    position: relative;
}
.blog-item figure.image-holder img{
    position: absolute;
    left: 0;
    top: 0;
    -o-object-fit: cover;
    object-fit: cover;
    width: 100%;
    height: 100%;
}
.blog-item .button-text path{
    fill: #e71de1;
    color: #e71de1;
}
.blog-item .button-text svg{
    margin-left: 5px;
}
.blog-item .button-text{
    color: #e71de1;
    font-weight: bold;
    font-size: 16px;
} 
.blog-item h4, 
.blog-item h4 a{
    font-weight: bold;
    color: #000;
    margin-top: 8px;
    margin-bottom: 10px;
}
.date-line {
    font-size: 0.9rem;
    color: #635d6f;
    font-weight: 500;
}
.blog-detail-pag img{
    width: 100%;
}
.blog-detail-pag h2{
    font-weight: bold;
    margin-bottom: 12px;
    margin-top: 12px;
}
.comments-meta h4 {
    font-size: 20px;
    font-weight: bold;
    display: block;
}
.comments-box .comment-inner {
    display: grid;
    grid-template-columns: 60px 1fr;
    grid-column-gap: 20px;
}
.comments-box .comment-thumb img {
    width: 60px;
    height: 60px;
    border-radius: 50%;
    margin-top: 5px;
}
.comments-meta h4 span {
    font-size: 14px;
    font-weight: 500;
    letter-spacing: -0.2px;
    text-transform: capitalize;
    color: #7a7a7a;
    display: block;
}
.comment-area p {
    font-size: 17px;
    line-height: 27px;
    margin-bottom: 10px;
}
/* Single Post Item */
.single-post-item {
    margin-top: 20px;
}
.comments-box  .comment-inner {
    background: #fff;
    -webkit-box-shadow: 0 1px 80px 12px rgb(26 40 68 / 6%);
    box-shadow: 0 1px 80px 12px rgb(26 40 68 / 6%);
    border-radius: 12px;
    padding: 20px;
    border-radius: 8px;
    margin-bottom: 15px;
}
.single-post-item textarea{
    min-height: 100px;
}
.blog-detail-pag img{
    border-radius: 10px;
}
button.removed{
    padding: 0px;
    font-size: 10px;
    color: red;
    background: #fff;
    margin-left: 5px;
    box-shadow: none;
}
button.unapprove,
button.approve {
    padding: 3px 8px;
    font-size: 10px;
}
.single-post-item h3 {
    font-size: 25px;
    font-weight: bold;
    margin-bottom: 25px;
}
.comments-box {
    margin-bottom: 20px;
}
.contact-outer{
    background: #fff;
    -webkit-box-shadow: 0 1px 80px 12px rgb(26 40 68 / 6%);
    box-shadow: 0 1px 80px 12px rgb(26 40 68 / 6%);
    border-radius: 12px;
    padding: 20px;
    border-radius: 8px;
}
.blog-listing {
    padding: 50px 0px;
}
@media screen and (max-width: 1200px){
    .right-vendro_filter1{
        width: 100%;
        padding-bottom: 0px;
    }
}
@media screen and (max-width: 991px) and (min-width: 768px){

    .nnnn_adfilter{
    display: none;
}
}
@media(max-width: 991.98px) {
    .right-vendro_filter1{
        padding-left: 0px;
    }
    li.vendorOutercatlist {
        flex: 33.33% 1;
        max-width: 33.33%;
        margin-bottom: 15px;
    }
}
.other-account~button{
    margin-bottom: 0px!important;
}
@media(max-width: 767.98px) {
    .section-tittle .tittle{
        font-size: 25px;
    }
    .section-tittle .tittle .shape {
        width: 72px;
    height: 38px;
    }
    .nnnn_adfilter {
        display: none;
    }
    li.vendorOutercatlist {
        flex: 50% 1;
        max-width: 50%;
    }
    .loginModalbootouter{
        width: 97%;
        height: auto;
    }
}
div#nav-tab .active .car-gigss {
    border: 2px solid #e71de1;
}

.imgwithtitle img{
    width: 34px;
    height: 34px;
    border-radius: 100px;
    object-fit: cover;
    object-position: center;
}
.nn_order_details-pay th{
    font-size: 14px;

    color: #000;
}
.nn_order_details-pay td{

    color: #000;
    font-size: 14px;
}
.imgwithtitle{
    display: flex;
    align-items: center;
    gap: 10px;

}
.cls_addpayout {
    color: var(--subtheme-color);
    background-color: var(--theme-color);
    padding: 8px 30px;
    border-radius: 3px;
}
.loginRight .text-center.mt-4 .btn-wrapper{
    font-family: "Ubuntu", sans-serif;
    transition: .4s;
    border: 1px solid transparent;
    background-image: linear-gradient(to right, #0f6aff, #f902e3);
    color: #fff;
    padding: 8px 34px;
    font-size: 16px;
    font-weight: 500;
    display: inline-block;
    border-radius: 8px;
    text-decoration: none;
}